export const serviceConfig = {
  identityApi: {
    BaseUrl: 'https://dev-moneymanage-identity.moneymanage.my/',
    // BaseUrl:"https://localhost:7094/",
    login: 'api/security/create-token',
    register: 'api/user',
    confim_email_request: 'api/user/confirm-email-request',
    confim_email: 'api/user/confirm-email',
    reset_password_request: 'api/user/reset-password-request',
    reset_password: 'api/user/reset-password',
    update_user: '/api/user/{id}',
  },
  moneyManageApi: {
    BaseUrl: 'https://dev-moneymanage.moneymanage.my/',
    // BaseUrl: "https://localhost:5078/",
    signalRHubUrl: 'wss://dev-moneymanage.moneymanage.my/notifications-hub',
    account: 'api/account',
    balance_account: 'api/account/{0}/balance',
    settings: 'api/account/settings',
    account_status: 'api/account/status',
    transaction: 'api/transaction',
    delete_transaction: 'api/transaction/{0}',
    transaction_ammount: 'api/transaction/ammount',
    transaction_types: 'api/transaction/types',
    get_source: 'api/transaction/sources/{0}',
    post_source: 'api/transaction/sources',
    put_source: 'api/transaction/sources',
    get_type: 'api/transaction/types',
    budget: 'api/budget',
    get_budget_by_id: 'api/budget/{0}',
    budget_types: 'api/budget/types',
    loan: 'api/loan',
    loanFrecuency: 'api/loan/frecuency',
    loanPaid: 'api/loan/paid',
  },
}

export default serviceConfig
